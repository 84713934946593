import React from 'react'
import { Link, graphql } from 'gatsby'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Hero from '../components/Hero'
import Flex from '../components/Flex'

const propTypes = {
  data: PropTypes.object,
}

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <Container smallplus>
      <h1>Seite nicht gefunden</h1>
    </Container>
    <Hero large fluid={data.notFoundImage.childImageSharp.gatsbyImageData} padding={0} height={`40vh`} />
    <Flex smallplus>
      <p>
        Die gew&uuml;nschte Seite existiert nicht, also{' '}
        <Styled.a href={`/`}>zurück nach Hause</Styled.a>
      </p>
    </Flex>
  </Layout>
)

NotFoundPage.propTypes = propTypes

export default NotFoundPage

export const query = graphql`{
  notFoundImage: file(
    relativePath: {eq: "404/daniel-jensen-UDleHDOhBZ8-unsplash.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
    }
  }
}
`
